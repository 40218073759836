import Vue from 'vue';
import Vuex from 'vuex';
import home from './home';
import device from './device';
import code from "./code";
import step from "./step";
import question from "./question";
import report from "./report";
import prescribeQuestion from "./prescribeQuestion";
import doctorReport from "./doctorReport";
import addUser from "./addUser";
import confirmAssess from "./confirmAssess";
import user from "./user";
import profile from "./profile";
import upload from "./upload";
import list from "./list";
import redEnvelope from "./redEnvelope";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    home,
    device,
    code,
    step,
    question,
    report,
    prescribeQuestion,
    doctorReport,
    addUser,
    confirmAssess,
    user,
    profile,
    upload,
    list,
    redEnvelope
  }
})
