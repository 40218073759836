import axios from "@/utils/axios";
import { user_change , userInfo , user_info, osspolicy, address } from "@/api";
import { Toast } from 'vant';
import {getBase64, getBinary} from "../utils";

let profile = {
    namespaced: true,
    state: {
        hospital:[],
        info:null,
        switchBool:false,
        nickBool:false,
        nickName:'',
        address:false,
        addressOption:[],
        province:'',
        region:''
    },
    mutations: {            //同步方法
        //用户信息
        putImage (state, file) {
            const blob = file.target && file.target.files && file.target.files[0] ? file.target.files[0] : null;
            if (blob) {
                getBase64 (blob).then ((base64) => {
                    state.info = {
                        ...state.info,
                        avatarUrl: base64
                    };
                });
                getBinary(blob).then((file) => {
                    axios(`/api${osspolicy}`).then((data)=>{
                        if(data.code === 1){
                            let res = data.data
                            const formData = new FormData();
                            let dir = res.Key + new Date().getTime();
                            formData.append("OSSAccessKeyId", res.OSSAccessKeyId);
                            formData.append("Policy", res.Policy);
                            formData.append("Signature", res.Signature);
                            formData.append("Key", dir);
                            formData.append("Callback", res.Callback);
                            formData.append("File", file);
                            axios(`https://${res.Host}`,formData,'POST',{},true).then(ress => {
                                if(ress.Code===0){
                                    const src = `https://${res.Host}/${ress.Data.FileName}`;
                                    state.info = {
                                        ...state.info,
                                        avatarUrl: src
                                    };
                                    axios(`/userapi${user_change}`,{
                                        ...state.info,
                                        avatarUrl: src
                                    },'PUT').then((data) => {
                                        data.code === 1 &&
                                        Toast.success("上传头像成功");
                                    })
                                }
                            })
                        }
                    })
                })
            }
        },
        infoAxios(state,parem,_this){
            axios(`/userapi${userInfo}`,{
                event:1,
                ...parem.data?parem.data:{}
            }).then((data)=>{
                if(data.code==1){
                    let data_1 = {};
                    if(parem.account_id){
                        data_1.id = parem.account_id;
                    }
                    this.dispatch('profile/accountData',{
                        data:data_1,
                        info:data.data,
                        fun:parem.fun,
                        _this:_this
                    });
                }
            });
        },

        //判断是否是医生
        accountAxios(state,parem){
            console.log(parem);
            axios(`/userapi${user_info}`,parem.data).then((data)=>{
                if(data.code==1){
                    state.info = {
                        ...parem.info,
                        ...data.data,
                        is_doctor:1
                    };
                    if(parem.fun){
                        parem.fun(state.info);
                    }else if(!data.data.doctor_material||data.data.doctor_material==2){
                        parem._this.$router.push('/upload');
                    }
                } else {
                    console.log({
                        ...parem.info,
                        is_doctor:0
                    });
                    state.info = {
                        ...parem.info,
                        is_doctor:0
                    };
                }
            });
        },

        //修改昵称
        userRevise(state){
            let option = {}
            if(state.nickName){
                option.nikeName = state.nickName;
            } else if(state.province && state.region){
                option.province = state.province;
                option.region = state.region;
            }else{
                Toast.fail('请输入昵称！');
                return;
            }
            axios(`/userapi${user_change}`,{
                ...state.info,
                ...option
            },'PUT').then((data)=>{
                if(data.msg){
                    Toast.success(data.msg);
                }
                state.nickBool = false;
                setTimeout(() => {
                    this.dispatch('profile/axiosMain');
                },1000);
            });
        },

        //填写昵称
        nickFun(state){
            state.nickBool = !state.nickBool;
        },
        //获取省市
        addressAxios(state){
            axios(`/userapi${address}`,{}).then((data)=>{
                if(data.code==1){
                    //洗数据
                    let options = [];
                    let nation = data.data.regions[0].children;   //国家
                    //省
                    nation.map((province)=>{
                        let option = {
                            text:province.name,
                            value:province.key,
                            children:[]
                        };
                        if(province.children){
                            province.children.map((city)=>{
                                let city_option = {
                                    text:city.name,
                                    value:city.key
                                };
                                option.children.push(city_option);
                            })
                        }
                        options.push(option)
                    })
                    state.addressOption = options;
                    state.address = true;
                }
            });
        },

        //改变数值
        change(state,data){
            state[data.key] = data.value;
        },
    },
    actions: {              //异步方法
        userReviseData({commit}){
            commit('userRevise')
        },
        accountData({commit},parem){
            commit('accountAxios',parem)
        },
        axiosMain({commit},_this){
            commit('infoAxios',_this)
        },
        axiosAddress({commit},_this){
            commit('addressAxios',_this)
        },
        setImage({commit},file){
            commit('putImage', file)
        }
    },
    getters: {},
}

export default profile;
