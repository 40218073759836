const problem = [{
    id: -7,
    title: "您的姓名是？",
    content: [''],
    dockId:7,
    show:true,
    alias:'姓名',
    no_select:false,        //是否选择
    is_multiple: 0,         //多重选择
},{
    id: -5,
    title: "请输入您的身份证号码",
    content: [''],
    dockId:5,
    alias:'身份证号',
    show:true,
    long:true,
    extra:'X',
    no_select:true,
    is_multiple: 0,
    msg: "请输入正确的身份证号码",
},{
    id: -6,
    title: "请输入您的医保卡号码",
    content: [''],
    dockId:6,
    alias:'医保卡号',
    show:true,
    long:true,
    extra:'X',
    is_multiple: 0,
    no_select: true,
    msg: "请输入正确的医保卡号码",
},{
    id: -1,
    title: "您的性别是？",
    dockId:1,
    show:true,
    content: [{
        label:'男',
        active:false,
        is_single:1
    }, {
        label:'女',
        active:false,
        is_single:1
    }],
    alias:'性别',
    no_select:false,
    is_multiple: 0,
},{
    id: -2,
    title: "您的出生日期是？",
    dockId:2,
    show:true,
    content: ['','','','00','00'],
    date:0,
    alias:'出生日期',
    no_select: true,
    is_multiple: 0,
    range: [12, 31],
    msg: "请输入正确的日期!",
},{
    id: -3,
    title: "您当前的身高是？",
    dockId:3,
    show:true,
    alias:'身高',
    content: [''],
    long:false,
    no_select: true,
    is_multiple:0,
    unit: "厘米（cm）",
    range: [100, 230],
    msg: "请输入正确的身高(100-230)",
},{
    id: -4,
    title: "您当前的体重是？",
    dockId:4,
    show:true,
    alias:'体重',
    content: [''],
    long:false,
    no_select:true,
    is_multiple: 0,
    unit: "公斤（kg）",
    range: [15, 150],
    msg: "请输入正确的体重(15-150)",
},{
    id: -8,
    title: "您当前的腹围是？",
    dockId:8,
    show:true,
    alias:'腹围',
    content: [''],
    long:false,
    no_select:true,
    is_multiple: 0,
    unit: "厘米（cm）",
    range: [50, 200],
    msg: "请输入正确的腹围(50-200)",
},{
    id: -9,
    title: "您容易过敏（对药物、食物、气味、花粉或在季节交替、气候变化时）吗？",
    dockId:9,
    show:true,
    content: [{
        label:'从来没有',
        active:false,
        is_single:1
    }, {
        label:'一年1、2次',
        active:false,
        is_single:1
    }, {
        label:'一年3、4次',
        active:false,
        is_single:1
    }, {
        label:'一年5、6次',
        active:false,
        is_single:1
    }, {
        label:'每次遇到上述原因都过敏',
        active:false,
        is_single:1
    }],
    alias:'过敏',
    no_select:false,
    is_multiple: 0,
}]

export default problem;