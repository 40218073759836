import axios from "@/utils/axios";
import { create , hongbao_info , can_report , get_sick_trend , account , qr , hongbao_lingqu , custom_list , custom_bind , tongji , lingqu_list , test_list , hongbao_send , hongbao_cancel } from "@/api";
import { Dialog } from 'vant';
const echarts = require('echarts/lib/echarts');
require('echarts/lib/component/grid');
require('echarts/lib/chart/bar');

let redEnvelope = {
    namespaced: true,
    state: {
        red_info:null,
        lingqu:[],
        tongji:null,
        custom_list:[],
        bar:{
            x:[],
            y:[],
            y1:[]
        }
    },
    mutations: {            //同步方法
        //业务员创建红包
        redAxios(state,params){
            axios (`/api${create}`, params.data,'POST').then((data)=>{
                if(data.code==1){
                    params.fun(data.data.hongbao_code);
                }
            });
        },
        //红包详情
        redInfoAxios(state,params){
            axios (`/api${hongbao_info}`, params.data).then((data)=>{
                if(data.code==1){
                    state.red_info = data.data;
                    if(params.fun){
                        params.fun(data.data);
                    }
                    this.dispatch('profile/axiosMain',{
                        data:{
                            id:data.data.user_id,
                            event:100
                        },
                        account_id:data.data.account_id,
                        _this:params._this,
                        fun:()=>{}
                    });
                }
            });
        },
        //判断是否可以进行红包检查
        redCanReportAxios(state,params){
            axios (`/api${can_report}`, params.data).then((data)=>{
                if(data.code==1){
                    if(params.fun){
                        params.fun(data.data);
                    }
                }else{
                    Dialog.alert({
                        title:'提示',
                        message: data.msg,
                    });
                }
            });
        },
        //获取报告症候的趋势
        trendAxios(state,params){
            axios (`/api${get_sick_trend}`, params).then((data)=>{
                if(data.code==1){
                    if(data.data.list){
                        let y=[],x=[],y1=[];
                        data.data.list.map((list)=>{
                            y1.push('');
                            y.push(list.sick_text);
                            x.push(list.count);
                        });
                        state.bar = {x,y,y1};
                    }else{
                        state.bar = {
                            x:[],
                            y:[],
                            y1:[]
                        }
                    }
                    let _this = this;
                    if(data.data.list){
                        setTimeout(function(){
                            _this.dispatch('redEnvelope/bar','trend');
                        },1);
                    }
                }
            });
        },
        //业务员红包统计列表(营销管理=>红包管理)
        accountAxios(state,params){
            axios (`/api${account}`, params.data).then((data)=>{
                if(data.code==1){
                    if(params.fun){
                        params.fun(data.data);
                    }
                }
            });
        },
        //红包检测后需要关注的临时参数二维码
        qrAxios(state,params){
            axios (`/api${qr}`, params.data).then((data)=>{
                if(data.code==1){
                    if(params.fun){
                        params.fun(data.data);
                    }
                }
            });
        },
        //领取红包
        lingquAxios(state,params){
            axios (`/api${hongbao_lingqu}`, params.data).then((data)=>{
                if(data.code==1){
                    if(params.fun){
                        params.fun(false);
                    }
                }else{
                    if(data.msg=='群发类的红包，一天只能领取一次，如需要请联系您的健康顾问。'){
                        params.fun(true);
                    }else{
                        Dialog.alert({
                            title:'提示',
                            message: data.msg,
                        });
                    }
                    
                }
            });
        },
        //红包业务端客户列表
        customListAxios(state,params){
            axios (`/api${custom_list}`, params).then((data)=>{
                if(data.code==1){
                    state.custom_list = data.data.list?data.data.list:[]
                }
            });
        },
        //红包业务端客户绑定
        customBindAxios(state,params){
            axios (`/api${custom_bind}`, params.data).then((data)=>{
                if(data.code==1){
                    if(params.fun){
                        params.fun(data.data);
                    }
                }
            });
        },
        //统计数据
        tongjiAxios(state,params){
            axios (`/api${tongji}`, params).then((data)=>{
                if(data.code==1){
                    state.tongji = data.data;
                }
            });
        },
        //红包领取记录
        lingquListAxios(state,params){
            state.lingqu = [];
            axios (`/api${lingqu_list}`, params).then((data)=>{
                if(data.code==1){
                    state.lingqu = data.data.list;
                }
            });
        },
        //红包检测记录
        testListAxios(state,params){
            state.lingqu = [];
            axios (`/api${test_list}`, params).then((data)=>{
                if(data.code==1){
                    state.lingqu = data.data.list?data.data.list:[];
                }
            });
        },
        //发送红包
        hongbaoSendAxios(state,params){
            axios (`/api${hongbao_send}`, params.data).then((data)=>{
                if(data.code==1){
                    if(params.fun){
                        params.fun();
                    }
                }else{
                    Dialog.alert({
                        title:'提示',
                        message: data.msg,
                    });
                }
            });
        },
        //取消红包
        hongbaoCancelAxios(state,params){
            axios (`/api${hongbao_cancel}`, params.data).then((data)=>{
                if(data.code==1){
                    if(params.fun){
                        params.fun();
                    }
                }else{
                    Dialog.alert({
                        title:'提示',
                        message: data.msg,
                    });
                }
            });
        },
        //趋势图
        bar(state,params){
            console.log(1);
            var chartDom = document.getElementById(params);
            
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                xAxis: {
                  max: 'dataMax'
                },
                yAxis: {
                    data: state.bar.y1           
                }
              };
            function run() {
                myChart.setOption({
                  series: [
                    {
                      type: 'bar',
                      barWidth: "20",
                      data: state.bar.x
                    }
                  ]
                });
            }
            setTimeout(function () {
                run();
            }, 0);
            option && myChart.setOption(option);
        }
    },
    actions: {              //异步方法
        axiosRed({commit},params){
            commit('redAxios',params)
        },
        axiosRedInfo({commit},params){
            commit('redInfoAxios',params)
        },
        axiosRedCanReport({commit},params){
            commit('redCanReportAxios',params)
        },
        axiosTrend({commit},params){
            commit('trendAxios',params)
        },
        axiosAccount({commit},params){
            commit('accountAxios',params)
        },
        axiosQr({commit},params){
            commit('qrAxios',params)
        },
        axiosLingqu({commit},params){
            commit('lingquAxios',params)
        },
        axiosCustomList({commit},params){
            commit('customListAxios',params)
        },
        axiosCustomBind({commit},params){
            commit('customBindAxios',params)
        },
        axiosTongji({commit},params){
            commit('tongjiAxios',params)
        },
        axiosLingquList({commit},params){
            commit('lingquListAxios',params)
        },
        axiosTestList({commit},params){
            commit('testListAxios',params)
        },
        axiosHongbaoSend({commit},params){
            commit('hongbaoSendAxios',params)
        },
        axiosHongbaoCancel({commit},params){
            commit('hongbaoCancelAxios',params)
        },
        bar({commit},params){
            commit('bar',params)
        },
    },
    getters: {
        
    },
}

export default redEnvelope;
