//防抖函数
export let deBounce = (fn, delay) => {
    let timer = null;
    return function (...args) {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(()=> {
            fn(...args);
        }, delay);
    }
}

//节流函数
export let throttle = (fn, delay) => {
    let flag = true;
    return function (...args) {
        if (!flag) return;
        flag = false;
        setTimeout(() => {
            fn(...args);
            flag = true;
        }, delay);
    }
}

//计算年龄
export function ages(str) {
    var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
    if (r == null) return false;
    var d = new Date(r[1], r[3] - 1, r[4]);
    if (
      d.getFullYear() == r[1] &&
      d.getMonth() + 1 == r[3] &&
      d.getDate() == r[4]
    ) {
      var Y = new Date().getFullYear();
      return Y - r[1];
    }
}

//随机数
export function RandomString(length) {
    var str = '';
    for ( ; str.length < length; str += Math.random().toString(36).substr(2) );
    return str.substr(0, length);
}

// 判断是否是小程序的 webview 环境
export function isInWechatMPO() {
    if (
        navigator.userAgent.match(/miniprogram/i) ||
        window.wxjs_environment == "miniprogram"
    ) {
        return true;
    } else {
        return false;
    }
}

//生成二维码
import QRCode from 'qrcode';
export function makecode(text){
    var imgurl = '';
    QRCode.toDataURL(text,
        {
            scale:7,
            margin:2,
            errorCorrectionLevel: 'H',
        },
        function (err, url) {
            imgurl = url;
        }
    );

    return imgurl;
}

//播放音频
class Paly{
    constructor() {
        this.audio = document.createElement("audio");
    }
    play(url){
        let device = sessionStorage.device;
        if( device && (device=='android'||device=='windows')){
            this.audio.src = url;
            this.audio.load();
            this.audio.play();
        }
    }
}

export let audioPaly = new Paly();

//获取cookie
export function getCookie(name){
    var prefix = name + "="
    var start = document.cookie.indexOf(prefix)
    if (start == -1) {
        return null;
    }
    var end = document.cookie.indexOf(";", start + prefix.length)
    if (end == -1) {
        end = document.cookie.length;
    }
    var value = document.cookie.substring(start + prefix.length, end)
    return decodeURI(value);
}

//获取二级域名前缀
export function getDomain(){
   let href =  window.location.href;
   if(href.indexOf("localhost")!=-1){
        return 'localhost';
   }else{
        return href.split(".")[0].split("//")[1];
   }
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function getBinary(file) {
    return new Promise((resolve, reject) => {
        getBase64(file)
          .then((res) => {
              const arr = res.split(",");
              const mime = arr[0].match(/:(.*?);/)[1];
              const bstr = atob(arr[1]);
              let n = bstr.length;
              const u8arr = new Uint8Array(n);
              while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
              }
              return resolve(new Blob([u8arr], {type: mime}));
          })
          .catch(reject);
    });
}
//计算上上上...周的开始时间和结束时间,week向前推导第几周,time:时间基数
export function getWeek(date,day){
    let time = new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime();
    if(date){   //在时间基数上加减
        time = new Date(new Date(date).toLocaleDateString()).getTime();
    }
    let end;
    if(date){
        if(day>0){
            end = new Date(time-1000*3600*24);
        }else if(day<0){
            end = new Date(time+1000*3600*24);
        }else{
            end = new Date(time);
        }
    }else{
        end = new Date(time);
    }
    let start = new Date(end.getTime()-day*1000*3600*24);
    if(day>0){
        let end_k = `${end.getFullYear()}-${dateZero(end.getMonth()+1)}-01`;
        return {
            start:`${start.getFullYear()}-${dateZero(start.getMonth()+1)}-${dateZero(start.getDate())}`,
            end:`${end.getFullYear()}-${dateZero(end.getMonth()+1)}-${dateZero(end.getDate())}`,
            start1:`${start.getFullYear()}-${dateZero(start.getMonth()+1)}-01`,
            end1:`${end.getFullYear()}-${dateZero(end.getMonth()+1)}-${getDaysInMonth(end_k)}`
        }
    }else{
        let start_k = `${start.getFullYear()}-${dateZero(start.getMonth()+1)}-01`;
        let bool = false;
        let date_0 = new Date();
        let start_0 = `${date_0.getFullYear()}-${dateZero(date_0.getMonth()+1)}-01`;
        if(new Date(start_0).getTime()==new Date(start_k).getTime()){
            bool = true;
        }
        return {
            start:`${end.getFullYear()}-${dateZero(end.getMonth()+1)}-${dateZero(end.getDate())}`,
            end:`${start.getFullYear()}-${dateZero(start.getMonth()+1)}-${dateZero(start.getDate())}`,
            start1:`${end.getFullYear()}-${dateZero(end.getMonth()+1)}-01`,
            end1:`${start.getFullYear()}-${dateZero(start.getMonth()+1)}-${bool?dateZero(date_0.getDate()):getDaysInMonth(start_k)}`,
            bool:bool
        }
    }
    
}

export function dateZero(data){
    return data>9?data:`0${data}`
}

export function getDaysInMonth(v) {
    const now = new Date(v);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const dateString = lastDay.toDateString();
    const day = dateString.split(' ')[2];
    return parseInt(day);
}


//计算上一页和下一页的显示控制
let pageConfig = [{
    key:1,value:1,age:0,sex:[],syndrome:[],other:[],company:[]   //证候或体质
},{
    key:2,value:1,age:0,sex:[],syndrome:[],other:[],company:[]   //分数描述
},{
    key:7,value:2,age:0,sex:[],syndrome:[],other:[],company:[]   //常见症状
},{
    key:8,value:2,age:0,sex:[],syndrome:[],other:[],company:[]   //重点预防疾病
},{
    key:3,value:3,age:0,sex:[],syndrome:[],other:[{key:'report_template_type',value:1}],company:[]   //体重指数
},{
    key:4,value:3,age:0,sex:[],syndrome:[],other:[],company:[]   //健康趋势图
},{
    key:5,value:4,age:0,sex:[],syndrome:[],other:[{key:'report_template_type',value:1}],company:[]   //身体健康状态综合评估
},{
    key:6,value:5,age:18,sex:[],syndrome:['平衡'],other:[],company:[]   //重大疾病风险预警
}];

export function pageFun(page,info){
    let bool = false;
    let boolAraag = [];
    let noBool = true;
    pageConfig.map((item,index)=>{
        console.log(`第${index}循环`,page,item.value);
        let boolOne = true;
        if(page == item.value){             //判断模块是否显示  value对应的是state.page  key对应的是report_section
            noBool = false;
            if(item.key!==0 && info.report_section.indexOf(item.key)==-1){  //不显示模块
                console.log('不显示模块',page,item.key,info.report_section);
                boolAraag.push(false);
                boolOne = false;
            }
            if( boolOne && info.age<item.age){          //限制年龄
                console.log('限制年龄',page,item.age,info.age);
                boolAraag.push(false);
                boolOne = false;
            }
            if(boolOne){
                item.sex.map((item1)=>{         //限制性别 1男2女
                    if(item1==info.sex){
                        console.log('限制性别',page,item1,info.sex);
                        boolAraag.push(false);
                        boolOne = false;
                    }
                })
            }
            if(boolOne){
                item.syndrome.map((item2)=>{    //限制证候
                    if(item2==info.sick_text_array[0]){
                        console.log('限制证候',page,item2,info.sick_text_array[0]);
                        boolAraag.push(false);
                        boolOne = false;
                    }
                })
            }
            if(boolOne){
                item.other.map((item3)=>{       //限制其他条件 比如报告类型
                    if(info[item3.key]!==item3.value){
                        console.log('限制其他条件',page,item3.key,info[item3.key],item3.value);
                        boolAraag.push(false);
                        boolOne = false;
                    }
                })
            }
            if(boolOne){
                if(item.company.length!==0){        //限制公司,这个模块只有这几家公司才能用,['appid']
                    let equipment = sessionStorage.equipment?JSON.parse(sessionStorage.equipment):{appid:null};
                    let company2 = equipment.appid||getDomain();
                    if(!item.company.includes(company2)){
                        console.log('限制公司',page,item.company,company2);
                        boolAraag.push(false);
                        boolOne = false;
                    }
                }
            }
            if(boolOne){
                console.log(index,'都过了');
                boolAraag.push(true);
            }
        }
    })
    console.log('没找到',noBool);
    if(noBool){
        bool = true;
    }
    boolAraag.map((araag)=>{
        if(araag){
            bool = true;
        }
    })
    console.log('结果---');
    console.log(page,bool);
    return bool;
}