import axios from "@/utils/axios";
import { getServer , getServerNoToken } from "@/api";
import { Notify } from 'vant';

let confirmAssess = {
    namespaced: true,
    state: {
        go:false,
        stepsImage:[]
    },
    mutations: {
        mainAxios(state,params){
            let company = {};
            if(params.sys_company_id){  //如果有公司id
                company.sys_company_id = params.sys_company_id
            }
            axios(`/api${params.server_type==7?getServerNoToken:getServer}`,{
                ...company,
                server_type:params.server_type,
            }).then((data)=>{
                if(data.code==1){
                    sessionStorage.setItem("flow", JSON.stringify(data.data));
                    state.go = true;
                    this.dispatch('confirmAssess/checkData');
                }else{
                    Notify(data.msg);
                }
            })
        },
        //改变数值
        change(state,data){
            state[data.key] = data.value;
        },
        //判断检测逻辑
        checkAxios(state){
            let flow = sessionStorage.getItem('flow');
            let flow_step = "";
            if(flow){
                flow_step = JSON.parse(flow).flow_info.flow_step;
            }
            switch(flow_step) {
                case 1:     //面
                    state.stepsImage = [
                        require("../../images/face.png"),
                        require("../../images/qus_2.png")
                    ]
                    break;
                case 4:     //舌+健康问诊
                    state.stepsImage = [
                        require("../../images/she_1.png"),
                        require("../../images/qus_2.png")
                    ]
                    break;
                case 6:     //面+中医问诊
                    state.stepsImage = [
                        require("../../images/face.png"),
                        require("../../images/qus_2.png")
                    ]
                    break;
                case 13:     //舌
                    state.stepsImage = [
                        require("../../images/lsd1.png"),
                        require("../../images/lsd2.png")
                    ]
                    break;
                case 14:     //舌+拍舌底
                    state.stepsImage = [
                        require("../../images/lsd1.png"),
                        require("../../images/lsd2.png")
                    ]
                    break;
                default:
                    state.stepsImage = [
                        require("../../images/face.png"),
                        require("../../images/she.png"),
                        require("../../images/qus.png")
                    ]
            }
        }
    },
    actions: {              //异步方法
        axiosData({commit},params){
            commit('mainAxios',params)
        },
        checkData({commit}){
            commit('checkAxios')
        },
    },
    getters: {},
}

export default confirmAssess;