import axios from "@/utils/axios";
import { report_list , prescribe_list , census_list } from "@/api";
import { getWeek , dateZero } from "@/utils";

let list = {
    namespaced: true,
    state: {
        report_list:[],
        type:1,
        user_id:0,
        health_load:true,
        report_load:true,
        total:1,
        page:0,  
        census:1,     //1:折线图  2.饼状图
        timeStart:false,    //开始时间
        timeEnd:false,      //结束时间
        time:false,
        judge:{
            max:0,
            min:0,
            average:0
        }
    },
    mutations: {
        //折线图和饼状图
        reportCensusAxios(state,obj){
            let timeName;
            if(obj.day>0){
                timeName = getWeek(state.timeStart,obj.day);
            }else if(obj.day==0){
                timeName = {
                    start:state.timeStart,
                    end:state.timeEnd
                }
            }else{
                timeName = getWeek(state.timeEnd,obj.day);
            }
            // state.time = `${timeName.start.replaceAll('-','.')}-${timeName.end.replaceAll('-','.')}`;
            state.time = obj.num==7?'最近的报告':`${timeName.start}至${timeName.end}`;
            state.timeStart = timeName.start;
            state.timeEnd = timeName.end;
            axios(`/api${census_list}`,{
                start_time:`${timeName.start} 00:00:00`,
                end_time:`${timeName.end} 23:59:59`,
                num:obj.num
            }).then((data)=>{
                if(data.code == 1){
                    if(!Object.keys(data.data).length > 0){
                        data.data={
                            date_time:[],
                            score:[]
                        }
                    }
                    //最大值
                    if(data.data.score.length>0){
                        state.judge.max = Math.max.apply(null,data.data.score);
                    }else{
                        state.judge.max = 0;
                    }
                    
                    //最小值
                    if(data.data.score.length>0){
                        state.judge.min = Math.min.apply(null,data.data.score);
                    }else{
                        state.judge.min = 0;
                    }

                    //平均数以及计算区间
                    let to50 = 0,to65=0,to80=0,to90=0,to100=0,sum=0;
                    data.data.score.map((score)=>{
                        if(0<=score&&score<50){
                            to50++;
                        }else if(50<=score&&score<65){
                            to65++;
                        }else if(65<=score&&score<80){
                            to80++;
                        }else if(80<=score&&score<90){
                            to90++;
                        }else{
                            to100++;
                        }
                        sum+=score;
                    });
                    if(data.data.score.length>0){
                        state.judge.average =  (sum/data.data.score.length).toFixed(0);
                    }else{
                        state.judge.average = 0;
                    }
                    if(state.census==1){        //折线图
                        let time = [];
                        data.data.date_time.map((date)=>{
                            let newTime = new Date(date);
                            let Month = newTime.getMonth()+1;
                            //Month = dateZero(Month);
                            let Day = newTime.getDate();
                            Day = dateZero(Day);
                            time.push(`${Month}-${Day}`);
                        });
                        this.dispatch('report/broken',{
                            dom:'census-broken',
                            date:time,
                            data:data.data.score,
                            max:100
                        });
                    }else{
                        this.dispatch('report/cake',{
                            dom:'census-broken',
                            data:[
                                { value: to50, name: '0-50' },
                                { value: to65, name: '50-65' },
                                { value: to80, name: '65-80' },
                                { value: to90, name: '80-90' },
                                { value: to100, name: '90-100' }
                            ]
                        });
                    }
                }
            })
        },
        //上一个月，下一个月
        monthFun(state,add){
            let day = 30;
            if(add>0){
                day = -30;
            }
            this.dispatch('list/axiosCensus',{
                num:0,
                day:day
            });
        },
        //上一周，下一周
        weekFun(state,add){
            let day = 7;
            if(add>0){
                day = -7;
            }
            this.dispatch('list/axiosCensus',{
                num:0,
                day:day
            });
        },

        dateChange(state,obj){
            state.timeStart = obj.start;
            state.timeEnd = obj.end;
            state.time = `${obj.start}至${obj.end}`;
        },
        
        //列表
        reportListAxios(state,obj){
            if(obj){
                state.user_id =  obj.user_id
            }else{
                obj = {
                    user_id:state.user_id
                }
            }
            if(state.report_list.length<state.total){
                state.page++;
                axios(`/api${state.type==1?prescribe_list:report_list}`,{
                    ...obj,
                    page:state.page
                }).then((data)=>{
                    if(data.code==1){
                        if(state.type==1){
                            state.health_load = false;
                            state.report_load = true;
                        }else{
                            state.report_load = false;
                            state.health_load = true;
                        }
                        if(data.data.list && data.data.list.length>0){
                            state.report_list = state.report_list.concat(data.data.list);
                            state.total = data.data.total;
                        }else{
                            state.total = 0;
                        }
                    }
                });
            }            
        },
        change(state,data){
            state[data.key] = data.value;
            state.total=1;
            state.page=0;
            state.report_list=[];
            state.health_load=true;
            state.report_load=true;
            if(data.user_id){
                this.dispatch('list/axiosMain',{
                    user_id:data.user_id,
                    type:4
                });
            }else{
                this.dispatch('list/axiosMain');
            }
        },
        changeRed(state,data){
            state[data.key] = data.value;
            state.total=1;
            state.page=0;
            state.report_list=[];
            state.health_load=true;
            state.report_load=true;
            if(data.user_id){
                this.dispatch('list/axiosMain',{
                    ...data.obj,
                    type:4
                });
            }else{
                this.dispatch('list/axiosMain',{
                    ...data.obj
                });
            }
        },
        closeFun(state){
            state.report_list=[];
            state.user_id=0;
            state.health_load=true;
            state.report_load=true;
            state.total=1;
            state.page=0;
            state.census=1;
            state.timeStart=false;
            state.timeEnd=false;
            state.time=false;
        }
    },
    actions: {
        axiosMain({commit},obj){
            commit('reportListAxios',obj);
        },
        axiosCensus({commit},obj){
            commit('reportCensusAxios',obj);
        },
    },
    getters: {},
}
export default list;